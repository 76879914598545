import React, { useState } from "react";

const ComingSoon = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch("http://localhost:5001/api/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage("🎉 Thank you for subscribing! Check your inbox.");
        setEmail(""); // Clear the input
      } else {
        setMessage("❌ Oops! Something went wrong. Try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("⚠️ Error! Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center px-4">
      {/* Title */}
      <h1 className="text-5xl font-bold text-blue-600 mb-6">UniGossip 🎓</h1>
      {/* Subtitle */}
      <p className="text-lg text-gray-600 text-center max-w-md mb-8">
        Be the first to know when we launch! Enter your email to join the waitlist and stay updated.
      </p>

      {/* Form */}
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center gap-4 w-full max-w-sm"
      >
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
          required
        />
        <button
          type="submit"
          disabled={isSubmitting}
          className={`w-full px-6 py-3 font-bold rounded-lg shadow-lg transition text-white ${
            isSubmitting ? "bg-blue-300 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-600"
          }`}
        >
          {isSubmitting ? "Submitting..." : "Notify Me"}
        </button>
      </form>

      {/* Message */}
      {message && (
        <p
          className={`mt-6 text-center px-4 py-2 rounded-lg ${
            message.startsWith("🎉")
              ? "text-green-600 bg-green-100"
              : "text-red-600 bg-red-100"
          }`}
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default ComingSoon;
