import React from "react";

const AboutUs = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center px-4 py-10">
      {/* Header */}
      <h1 className="text-5xl font-bold text-blue-600 text-center">About UniGossip 🎓</h1>
      <p className="text-lg text-gray-600 text-center max-w-3xl mt-6">
        Welcome to the future of university chatter! We’re creating a platform where students can connect, 
        share, and occasionally spill the tea ☕ (anonymously, of course). Here’s what’s brewing at UniGossip:
      </p>

      {/* Roadmap */}
      <div className="relative mt-12 w-full max-w-4xl">
        {/* Vertical Line */}
        <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-blue-500"></div>

        {/* Milestone 1 */}
        <div className="relative flex items-center mb-12">
          <div className="w-6 h-6 bg-blue-500 rounded-full absolute left-1/2 transform -translate-x-1/2"></div>
          <div className="w-1/2 text-right pr-6">
            <h3 className="text-2xl font-bold text-gray-700">1. Birth of the Idea 💡</h3>
            <p className="text-gray-500 mt-2">
              One day, we thought, “What if students had a place to share their thoughts without the fear of judgment?” 
              Thus, UniGossip was conceived—an idea born from late-night brainstorming sessions and too much caffeine.
            </p>
          </div>
          <div className="w-1/2"></div>
        </div>

        {/* Milestone 2 */}
        <div className="relative flex items-center mb-12">
          <div className="w-6 h-6 bg-blue-500 rounded-full absolute left-1/2 transform -translate-x-1/2"></div>
          <div className="w-1/2"></div>
          <div className="w-1/2 text-left pl-6">
            <h3 className="text-2xl font-bold text-gray-700">2. Prototype Testing 🧪</h3>
            <p className="text-gray-500 mt-2">
              Our first prototype will launch in a single university, allowing students to share, laugh, and learn. 
              We’ll gather feedback, squash bugs 🐞, and fine-tune every pixel.
            </p>
          </div>
        </div>

        {/* Milestone 3 */}
        <div className="relative flex items-center mb-12">
          <div className="w-6 h-6 bg-blue-500 rounded-full absolute left-1/2 transform -translate-x-1/2"></div>
          <div className="w-1/2 text-right pr-6">
            <h3 className="text-2xl font-bold text-gray-700">3. Beta Launch 🚀</h3>
            <p className="text-gray-500 mt-2">
              After ironing out the wrinkles, we’ll roll out UniGossip to multiple universities. Expect features like 
              anonymous posting, university-specific channels, and trending discussions.
            </p>
          </div>
          <div className="w-1/2"></div>
        </div>

        {/* Milestone 4 */}
        <div className="relative flex items-center">
          <div className="w-6 h-6 bg-blue-500 rounded-full absolute left-1/2 transform -translate-x-1/2"></div>
          <div className="w-1/2"></div>
          <div className="w-1/2 text-left pl-6">
            <h3 className="text-2xl font-bold text-gray-700">4. The Big Vision 🌍</h3>
            <p className="text-gray-500 mt-2">
              Eventually, we’ll expand globally—connecting students from every corner of the world. Expect exclusive 
              features, smarter discussions, and maybe even virtual coffee meetups ☕.
            </p>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="mt-16 text-center text-gray-500">
        <p className="text-lg">
          UniGossip: Where students connect, share, and sometimes spill the tea. Built for students, by student. 💻
        </p>
        <p className="text-sm mt-2">
          © 2024 UniGossip. Let’s make campus life better, one anonymous post at a time!
        </p>
      </footer>
    </div>
  );
};

export default AboutUs;
